<template>
  <div>
    <div class="uk-grid-medium" uk-grid>
      <div class="uk-width-5-5">
        <app-bread-crumb :list="breadCrumbList"/>

        <template v-if="errorMessages">
          <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
            <a class="uk-alert-close" uk-close/>
            <p style="color: red">
              <i class="uil-exclamation-triangle"/> {{ error }}
            </p>
          </div>
        </template>
        <div v-if="successMessage" class="uk-alert-success" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: green">
            <i class="uil-exclamation-triangle"/> {{ successMessage }}
          </p>
        </div>

        <div uk-grid="masonry: pack">
          <exam-properties-menu ref="examMenuRef" :initial-data="examMenuData" :is-pre-post-active="isPrePostActive" menu-id="exam-properties" />

          <div class="card uk-width-3-4@m position-relative p-0">
            <div v-if="loading" class="loading-bar">
              <div class="text-info" uk-spinner="ratio: 4"></div>
            </div>
            <div class="card-body">
              <div class="uk-flex uk-flex-middle uk-flex-between">
                <h4 class="m-0"><i class="uil-pen"></i> {{ $t("exam.add") }}</h4>
                <button :uk-toggle="'#exam-properties'" class="uk-button uk-button-default uk-margin-small-right"
                        type="button"><i class="uil-cog"/> {{ $t('exam.exam_features') }}
                </button>
              </div>
              <hr/>
              <div class="row" :class="{'prepost-disable-all-questions':isPrePostActive}" >
                <div class="col-xl-12 m-auto">
                  <form action="">
                    <div v-if="isPrePostActive" class="prepost-message-disable-text">{{$t('general.prepost_disable_message')}}</div>
                    <questions-demo  ref="myEvent"  :importedExam="questions"
                                    :questions="[]" action="create" model-type="Exam"/>

                    <div class="d-flex justify-content-end  align-items-center">
                      <toggle-button v-model="is_default"
                                     :labels="{ checked: $t('exam.default'), unchecked: $t('exam.not_default') }"
                                     :sync="true" :width="120" color="#ae71ff"/>
                    </div>

                    <div class="uk-card-footer float-right pb-0 pr-0">
                      <button  class="uk-button uk-button-primary small" type="button" @click="saveForm">
                        <i class="icon-feather-arrow-right"></i> {{ $t("general.save") }}
                      </button>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import store from "@/core/services";

import Questions from "@/view/components/question/Questions";
import ExcelQuestionImporter from "@/view/company/Exam/components/ExcelQuestionImporter.vue";
import QuestionPool from "@/view/company/Exam/components/QuestionPool.vue";

import module, {
  BASE_URL, ERROR,
  LOADING, MODULE_NAME,
  SUCCESS,
  GET_ITEM, UPDATE_ITEM,
} from "@/core/services/store/exam.module";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import AppBreadCrumb from "@/view/components/breadCrumb/AppBreadCrumb.vue";

const _MODULE_NAME = MODULE_NAME;
import QuestionsDemo from "@/view/components/question/Questions-demo.vue";
import ExamPropertiesMenu from "@/view/company/Exam/components/ExamPropertiesMenu.vue";
import AnzeraPowerChat from "@/view/components/anzera-power/anzera-power-chat/anzera-power-chat.vue";

export default {
  name: "examAdd",
  components: {
    QuestionPool, ExcelQuestionImporter, draggable,
    Questions, DefaultLoading, QuestionsDemo,
    AppBreadCrumb, ExamPropertiesMenu, AnzeraPowerChat
  },
  data() {
    return {
      errorMessages: [],
      successMessage: null,
      show_result: true,
      duration: 20,
      is_default: false,
      questions: [],
      is_loading: false,
      file: null,
      file_value: null,
      importedExam: null,
      fileInfo: {
        name: "",
        count: 0,
        file: null,
      },
      is_added: false,
      examMenuData: {},
      breadCrumbList: [{name: this.$t("exam.exams")}, {name: this.$t("exam.add")}],
      exam: [],
      isPrePostActive:false
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, module);
  },
  computed: {
    loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) {
      },
    },
    error: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) {
      },
    },
    success: {
      get() {
        return store.getters[_MODULE_NAME + "/" + SUCCESS];
      },
      set(value) {
      },
    },
  },
  methods: {
    saveForm() {
      let self = this;

      let myQuestions = self.validateExamData();
      if (!myQuestions) return false;

      let formData = self.buildFormData(myQuestions);

      store
          .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
            url: BASE_URL + "/" + self.$route.params.id,
            contents: formData,
          });

    },
    validateExamData() {
      let self = this;

      self.examMenuData= {...self.$refs.examMenuRef.handleNewExamMenu()}
      let myQuestions = self.$refs.myEvent.handleNewQuestions();

      // DOM dan pre-post disable class'larına mudahale edilirse , buradan engelle
      if (this.isPrePostActive) {
          self.errorMessages.push(this.$t("general.pre_post_exam_update_warning"));
          self.scrollToTop();
          return false;
      }

      // Soru yoksa hata
      if (myQuestions.length === 0) {
        self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.question")));
        self.scrollToTop();
        return false;
      }

      // Sınav adı kontrolü
      if (!self.examMenuData.exam_name || self.examMenuData.exam_name.length < 1) {
        self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.name")));
        self.scrollToTop();
        return false;
      }

      // Geçme notu kontrolü
      if (self.examMenuData.has_exam_pass_grade) {
        if (self.examMenuData.passmark == null || +self.examMenuData.passmark < 1) {
          self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.passmark")));
          self.scrollToTop();
          return false;
        }
      }

      // Sınav süresi kontrolü
      if (self.examMenuData.duration == null || +self.examMenuData.duration < 1) {
        self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.duration")));
        self.scrollToTop();
        return false;
      }

      //Sınav Şıkları kontrol
      let hasError = myQuestions.some((question, questionIndex) => {

        let isCorrectCount = 0;
        let hasNullName = false;
        let hasNullMedia = false;

        if (question.text === '') {
          //editor alanındaki sınav sorusu
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.text")));
          self.scrollToTop();
          return true
        }

        if (self.examMenuData.every_question_has_score) {
          //sidebar da (Her sorunun bir puanı olsun) seçili ise , her sorunun belli bir puanı olacak
          if (question.questionScore == null || question.questionScore < 1) {
            self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.questionScore_X")));
            self.scrollToTop();
            return true
          }
        }

        if (self.examMenuData.every_question_has_time_limit) {
          //sidebar da (Her sorunun bir süre sınırı olsun ) seçili ise , her sorunun belli bir süresi olacak
          if (question.questionDuration == null || question.questionDuration < 1) {
            self.errorMessages.push(this.$t("validation.required_field").replace(":NAME", self.$t("exam.questionDuration")));
            self.scrollToTop();
            return true
          }
        }

        question.options.forEach(option => {
          //her sorunun Şıkları için kontrol yap
          if (option.name === '') hasNullName = true;
          if (option.is_correct) isCorrectCount++;
          if (typeof option.media === "undefined") hasNullMedia = true;
        });

        if (hasNullName && (question.type !== 4 && question.type !== 5)) {
          //boş şık kontrolü
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.option_name")));
          self.scrollToTop();
          return true;
        }

        if (isCorrectCount === 0) {
          //en az bir doğru cevap olmalı
          self.errorMessages.push(self.$t("question.required.true_count"));
          self.scrollToTop();
          return true;
        }

        if (question.type === 1 && isCorrectCount > 1) {
          //çoktan seçmeli, en fazla 1 doğru cevap olabilir
          self.errorMessages.push(self.$t('question.required.must_have_max_one_correct_answer'));
          self.scrollToTop()
          return true;
        }

        if (hasNullMedia && (question.type === 4 || question.type === 5)) {
          //media controlling
          self.errorMessages.push(self.$t("validation.required_field").replace(":NAME", self.$t("question.attributes.option_media")));
          self.scrollToTop();
          return true;
        }

      });

      if (hasError) {
        // şıklarda hata varsa
        self.scrollToTop();
        return false;
      }

      // Eğer tüm validasyonlar geçerse, myQuestions'ı da geri döndürebiliriz
      return myQuestions;
    },
    buildFormData(myQuestions) {

      let self = this;
      let formData = new FormData();

      myQuestions.forEach((question, questionIndex) => {
        formData.append('questions[' + questionIndex + '][id]', question.id);
        formData.append(`questions[${questionIndex}][type]`, question.type);
        formData.append(`questions[${questionIndex}][text]`, question.text);
        formData.append(`questions[${questionIndex}][media]`, (question.media));

        question.options.forEach((option, optionIndex) => {
          formData.append('questions[' + questionIndex + '][options][' + optionIndex + '][id]', option.id);
          formData.append(`questions[${questionIndex}][options][${optionIndex}][name]`, option.name);
          formData.append(`questions[${questionIndex}][options][${optionIndex}][is_correct]`, option.is_correct);
          formData.append(`questions[${questionIndex}][options][${optionIndex}][media]`, option.media ?? '');

          /* if (question.type === 4 || question.type === 5) {
             formData.append(`questions[${questionIndex}][options][${optionIndex}][media]`, option.media);
           }*/
        });
      });

      // Exam menü alanlarını ekleme
      formData.append("id", self.examMenuData.id);
      formData.append("name", self.examMenuData.exam_name);
      formData.append("passmark", self.examMenuData.passmark);
      formData.append("duration", self.examMenuData.duration);
      formData.append("is_default", self.examMenuData.is_default ? 1 : 0);
      formData.append("is_mix_questions", self.examMenuData.is_mix_questions ? 1 : 0);
      formData.append("is_mix_answers", self.examMenuData.is_mix_answers ? 1 : 0);
      formData.append("show_right_answers", self.examMenuData.show_right_answers ? 1 : 0);
      formData.append("show_user_answers", self.examMenuData.show_user_answers ? 1 : 0);
      formData.append("show_result", self.examMenuData.show_result ? 1 : 0);
      formData.append("is_compulsory", self.examMenuData.is_compulsory ? 1 : 0);
      formData.append("pre_post", self.examMenuData.pre_post ? 1 : 0);

      //bu alanlar ayrıca BE'de de oluşturulmalı
      formData.append("has_exam_pass_grade", self.examMenuData.has_exam_pass_grade ? 1 : 0);
      formData.append("every_question_has_time_limit", self.examMenuData.every_question_has_time_limit ? 1 : 0);
      formData.append("every_question_has_score", self.examMenuData.every_question_has_score ? 1 : 0);
      formData.append("if_failed_from_exam", self.examMenuData.if_failed_from_exam);
      formData.append("if_exit_from_exam", self.examMenuData.if_exit_from_exam);

      return formData;
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    filterResult() {
      let self = this;
      this.$store.dispatch(_MODULE_NAME + "/" + GET_ITEM, {
        url: BASE_URL + "/" + this.$route.params.id,
      }).then((response) => {
        this.exam = response.data;
        //prepost active ise dom mudahalesi ile değiştirilemesin
        this.isPrePostActive=this.exam?.pre_post===1
        this.setQuestions();
        this.setExamMenuData()
      })
    },
    setQuestions() {
      let self = this;
      let questions = [];
      if (typeof self.exam.questions !== "undefined") {
        self.exam.questions.forEach(question => {
          let options = [];
          question.options.forEach(option => {
            options.push({
              id: option.id,
              name: option.text,
              is_correct: (option.is_correct === 1),
              media: option.media,
              //preview: question.question_type_id === 4 || question.question_type_id === 5 ? '<div class="file-info"><img src="' + option.question_option_media + '" alt=""></div>' : null,
            })
          });
          questions.push({
            id: question.id,
            type: question.question_type_id,
            text: question.text,
            media: question.media,
            options: options,
            preview: question.question_type_id === 3 || question.question_type_id === 5 ? '<div class="file-info"><img src="' + question.question_media + '" alt=""></div>' : null,
            audioPreview: question.question_type_id === 7 ? '<div class="file-info"><div>Ses Dosyası Adı: <span>' + question.media + '</span></div></div>' : null,
            videoPreview: question.question_type_id === 8 ? '<div class="file-info"><div>Video Dosyası Adı: <span>' + question.media + '</span></div></div>' : null,
            previewReply: [],
            //questionScore: null, Be den gelince bağla
            //questionDuration: null
          });
        });
      }

      /*
      *     id,
        type: 1,
        text,
        media,
        options,
        preview: null,
        audioPreview: null,
        videoPreview: null,
        previewReply: [],
        questionScore: null,
        questionDuration: null*/

      this.questions = questions;
    },
    setExamMenuData() {
      let exam = this.exam;

      this.examMenuData = {
        exam_name: exam.name || '',
        passmark: exam.passmark ?? 50,
        if_failed_from_exam: 'retry',
        if_exit_from_exam: 'continue',
        duration: exam.duration ?? 20,

        show_right_answers: exam.show_right_answers === 1,
        show_user_answers: exam.show_user_answers === 1,
        is_mix_questions: exam.is_mix_questions === 1,
        is_mix_answers: exam.is_mix_answers === 1,
        show_result: exam.show_result === 1,
        is_compulsory: exam.is_compulsory === 1,
        pre_post: exam.pre_post === 1,

        has_exam_pass_grade: false,
        every_question_has_time_limit: false,
        every_question_has_score: false,
      };
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.successMessage = this.$t("general.successfully_updated");
        this.filterResult();
        this.$router.push({name: "company.exams.list"});
      }
    },
    error: function (val) {
      if (val) {
        this.scrollToTop();
        this.errorMessages.push(val);
      }
    },
  },
  mounted() {
    this.error = [];
    this.filterResult();
  },
  created: function () {
    this.debouncedResetValues = _.debounce(this.resetMessages, 5000);
  },
};
</script>
<style scoped>
.prepost-disable-all-questions {
  pointer-events: none;
  opacity: 0.5;
}
.prepost-message-disable-text {
  color: #bc0f0f;
  font-weight: bold;
  font-size: 14px;
}

.selected_questions {
  color: #ae71ff;
  border: 1px solid #eaeaea;
  display: block;
  font-size: 13px;
  line-height: 33px;
  padding: 1px 7px;
  border-radius: 8px;
}

.loading-bar {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgba(238, 238, 238, 0.5);
  display: flex;
  justify-content: center;
  align-items: center
}
</style>
